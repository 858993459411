import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            title: '文档中心',
            name: 'home',
            index: '0',
            redirect: {
                name: 'api'
            }
        },
        {
            path: '/api/:type',
            title: '文档中心',
            name: 'api',
            index: '1',
            component: (resolve) => {
                require(['@/pages/Api/index.vue'], resolve)
            }
        }
    ]
})
