<template>
    <div id="app" :class="bodyClass">
        <router-view/>
    </div>
</template>

<style lang="less" src="@/styles/app.less"></style>

<script>
    export default {
        name: 'App',
        data() {
            return {
                bodyClass: ''
            }
        },
        watch: {
            $route() {
                if (this.$route.name) {
                    this.bodyClass = "body-" + this.$route.name
                }
            }
        }
    }
</script>
