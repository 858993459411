import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueHighlightJS from 'vue-highlightjs'
import App from './App.vue'
import router from './router'

Vue.use(ElementUI)

// 代码高亮
Vue.use(VueHighlightJS)
import 'highlight.js/styles/hybrid.css'

Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})